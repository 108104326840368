import React from 'react';
import { Flex } from '@chakra-ui/core';
import { useWindowSize } from 'react-use';

type BackgroundProps = Readonly<
  React.ComponentProps<typeof Flex> & { component?: React.FunctionComponent }
>;

function Background({
  children,
  component: Component,
  ...props
}: BackgroundProps) {
  const { height } = useWindowSize();
  return (
    <Flex
      bg="lightTeal"
      w="100vw"
      minH={`${height}px`}
      align="center"
      justify="center"
      direction="column"
      color="white"
      position="relative"
    >
      {Component && <Component />}
      <Flex
        w="100vw"
        minH={`${height}px`}
        align="center"
        justify="center"
        direction="column"
        zIndex={0}
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
}

export default Background;
