/**
 * The endpoint of the server.
 */
export const GRAPHQL_ENDPOINT =
  process.env.REACT_APP_GRAPHQL_ENDPOINT ?? '/graphql';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? '';
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID ?? null;
export const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION ?? '6';
