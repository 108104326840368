import React, { Suspense, lazy, ReactElement, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocalization } from '@fluent/react';
import {
  getAccessToken,
  isAccessTokenExpired,
} from '../../helpers/accessTokenStorage';
import Background from '../Background';
import HistoryRouter from '../HistoryRouter';
import Spinner from '../Spinner';
import history from '../../history';
import firebase from 'firebase/app';
import { useAccount } from '../../components/AccountProvider';
import AudioContextProvider from '../AudioContextProvider';

const SigninPage = lazy(() => import('../../containers/SigninPage'));
const WelcomePage = lazy(() => import('../../containers/WelcomePage'));
const LessonsPage = lazy(() => import('../../containers/LessonsPage'));
const ActivityListPage = lazy(
  () => import('../../containers/ActivityListPage')
);

const RoutinePage = lazy(() => import('../../containers/RoutinePage'));

const Loading = (
  <Background>
    <Spinner />
  </Background>
);

function Router() {
  const { l10n } = useLocalization();
  const pageTitle = l10n.getString('default-page-title');
  return (
    <Suspense fallback={Loading}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HistoryRouter history={history}>
        <Analytics>
          <AudioContextProvider>
            <Routes>
              <ProtectedRoute path="/" element={<WelcomePage />} />
              <ProtectedRoute path="/lessons" element={<LessonsPage />} />
              <ProtectedRoute path="/courses/:courseId">
                <ProtectedRoute path="/" element={<LessonsPage />} />
                <ProtectedRoute
                  path="lessons/:lessonId/*"
                  element={<ActivityListPage />}
                />
              </ProtectedRoute>
              {/* can access these activities outside of an activity */}
              <ProtectedRoute path="/routine/*" element={<RoutinePage />} />
              <Route path="/signin" element={<SigninPage />} />
            </Routes>
          </AudioContextProvider>
        </Analytics>
      </HistoryRouter>
    </Suspense>
  );
}

type ProtectedRouteProps = {
  path: string;
  element?: ReactElement;
  children?: JSX.Element[];
};

function ProtectedRoute(props: ProtectedRouteProps) {
  const accessToken = getAccessToken();
  const isSessionExpired = isAccessTokenExpired();
  const { account } = useAccount();

  if (!accessToken || !account)
    return (
      <Navigate
        to="/signin"
        replace
        state={{ from: props.path, isSessionExpired }}
      />
    );
  return <Route {...props}>{props.children}</Route>;
}

function Analytics({ children }: { children: JSX.Element }) {
  const location = useLocation();
  useEffect(() => {
    if (firebase.apps.length === 0) {
      // Firebase has not been initialised.
      return;
    }
    const analytics = firebase.analytics();
    analytics.logEvent('page_view', {
      page_path: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);
  return children;
}

export default Router;
