import React from 'react';
import { theme, ThemeProvider as Provider, CSSReset } from '@chakra-ui/core';

/**
 * custom svg icons
 */
const customIcons = {
  viewIcon: {
    path: (
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 12.5C8.24 12.5 6 10.26 6 7.5C6 4.74 8.24 2.5 11 2.5C13.76 2.5 16 4.74 16 7.5C16 10.26 13.76 12.5 11 12.5ZM8 7.5C8 5.84 9.34 4.5 11 4.5C12.66 4.5 14 5.84 14 7.5C14 9.16 12.66 10.5 11 10.5C9.34 10.5 8 9.16 8 7.5Z"
          fill="white"
        />
      </g>
    ),
    // If the icon's viewBox is `0 0 24 24`, you can ignore `viewBox`
    viewBox: '0 -5 24 24',
  },
  hiddenIcon: {
    path: (
      <g fill="currentColor">
        <path
          d="M1.24731 1C1.69161 1.96264 2.64914 3.35297 4.13745 4.60094M23.2473 1C22.803 1.96264 21.8455 3.35297 20.3572 4.60094M8.24731 6.88301C9.432 7.26739 10.7645 7.5 12.2473 7.5C13.7301 7.5 15.0626 7.26739 16.2473 6.88301M8.24731 6.88301L7.5 9.5M8.24731 6.88301C6.59053 6.34545 5.22284 5.51105 4.13745 4.60094M16.2473 6.88301L17 9.5M16.2473 6.88301C17.9041 6.34545 19.2718 5.51105 20.3572 4.60094M4.13745 4.60094L2.5 6.5M20.3572 4.60094L22 6.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    ),
    viewBox: '0 -10 24 24',
  },
  alertIcon: {
    path: (
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM10.5 11C9.95 11 9.5 10.55 9.5 10V6C9.5 5.45 9.95 5 10.5 5C11.05 5 11.5 5.45 11.5 6V10C11.5 10.55 11.05 11 10.5 11ZM9.5 13V15H11.5V13H9.5Z"
        />
      </g>
    ),
    viewBox: '0 -3 24 24',
  },
  chevronBack: {
    path: (
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 0C8.05933 0 0 8.05933 0 18C0 27.9407 8.05933 36 18 36C27.9407 36 36 27.9407 36 18C36 8.05933 27.9407 0 18 0ZM21.0557 25.3289C21.1897 25.4562 21.2968 25.6091 21.3708 25.7784C21.4448 25.9478 21.4841 26.1302 21.4865 26.315C21.4889 26.4998 21.4542 26.6832 21.3846 26.8544C21.3149 27.0256 21.2117 27.1811 21.0811 27.3118C20.9504 27.4425 20.7949 27.5457 20.6237 27.6153C20.4525 27.685 20.2691 27.7196 20.0843 27.7173C19.8995 27.7149 19.717 27.6756 19.5477 27.6016C19.3783 27.5276 19.2255 27.4204 19.0982 27.2864L10.7905 18.9788C10.531 18.7191 10.3853 18.3671 10.3853 18C10.3853 17.6329 10.531 17.2809 10.7905 17.0213L19.0982 8.71356C19.36 8.46485 19.7085 8.32825 20.0696 8.33287C20.4306 8.3375 20.7756 8.48298 21.0309 8.7383C21.2863 8.99363 21.4317 9.33859 21.4364 9.69965C21.441 10.0607 21.3044 10.4093 21.0557 10.6711L13.7276 18L21.0557 25.3289Z"
        />
      </g>
    ),
    viewBox: '0 0 38 38',
  },
};

/**
 * Can customise our theme here
 */
const customTheme = {
  ...theme,
  icons: {
    ...theme.icons,
    ...customIcons,
  },
  colors: {
    ...theme.colors,
    darkestGrey: '#323232',
    darkGrey: '#555555',
    grey: '#888888',
    grassGreen: '#ADB74F',
    lightGrey: '#BBBBBB',
    lightestGrey: '#D8D8D8',
    errorRed: '#FF6666',
    goodGreen: '#6BFF68',
    cautionAmber: '#FFAA55',
    activeBlue: '#6666FF',
    lilyMagenta: '#772390',
    nightNavy: '#002548',
    // teal conflicts with default teal color object so renamed this to capital Teal to keep naming consistent with brand guide
    Teal: '#4F9E95',
    lightTeal: '#97C2BD',
    darkTeal: '#02665B',
    // TODO: remove when upgrade to chakra v1. This is a work around for using white in chakra-ui components which add .500 to the color provided
    whiteShades: {
      500: '#FFF',
    },
    // TODO: remove when upgrade to chakra v1. This is a work around for using white in chakra-ui components which add .500 to the color provided
    NightNavy: {
      500: '#002548',
    },
  },
  fonts: {
    ...theme.fonts,
    primary: 'Montserrat',
    secondary: 'Roboto',
  },
  fontSizes: {
    ...theme.fontSizes,
    '5xl': '40px',
  },
};

function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <Provider theme={customTheme}>
      <CSSReset />
      {children}
    </Provider>
  );
}

export default ThemeProvider;
