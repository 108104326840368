import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } from './config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  release: SENTRY_RELEASE,
  integrations: [new Integrations.BrowserTracing()],
  // Sample all traces for now. This may need to be turned down to avoid
  // burning through the Sentry quota.
  tracesSampleRate: 1,
});
