import * as Sentry from '@sentry/react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AudioContext, IAudioContext } from 'standardized-audio-context';

type Props = Readonly<{
  children: React.ReactNode;
}>;

const AudioContextContext = createContext<IAudioContext | undefined>(undefined);

function AudioContextProvider({ children }: Props) {
  const [audioContext] = useState(() => {
    return new AudioContext();
  });
  useEffect(() => {
    return () => {
      audioContext.close().catch((err) => {
        Sentry.captureException(err);
      });
    };
  }, [audioContext]);
  return (
    <AudioContextContext.Provider value={audioContext}>
      {children}
    </AudioContextContext.Provider>
  );
}

export function useAudioContext() {
  const context = useContext(AudioContextContext);
  if (context === undefined) {
    throw new Error(
      'useAudioContext must be used within an AudioContextProvider'
    );
  }
  return context;
}

export default AudioContextProvider;
