import * as Sentry from '@sentry/browser';
import Background from '../Background';
import React, { useEffect, createContext } from 'react';
import Spinner from '../Spinner';
import firebase from 'firebase/app';
import { gql, useQuery } from '@apollo/client';

import type { Account } from '../../types/account';

interface AccountContextType {
  account: Account | null;
  fetchAccount: () => void;
}

interface ViewerPayload {
  viewer: Account;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

const GET_VIEWER = gql`
  query GetViewer {
    viewer {
      id
      username
      learner {
        id
      }
    }
  }
`;

function AccountProvider({ children }: { children: React.ReactElement }) {
  const { data, loading, refetch } = useQuery<ViewerPayload>(GET_VIEWER, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data == null || data.viewer == null || data.viewer.id == null) {
      Sentry.setUser(null);
      // @ts-ignore
      if (window.hj) window.hj('identify', null);
      // Make sure that Firebase has been initialised.
      if (firebase.apps.length > 0) {
        // @ts-ignore
        firebase.analytics().setUserId(null);
      }
    } else {
      Sentry.setUser({ id: data.viewer.id, username: data.viewer.username });
      // @ts-ignore
      if (window.hj) {
        // @ts-ignore
        window.hj('identify', data.viewer.id, {
          username: data.viewer.username,
        });
      }
      // Make sure that Firebase has been initialised.
      if (firebase.apps.length > 0) {
        firebase.analytics().setUserId(data.viewer.id);
      }
    }
  }, [data]);

  const contextValue: AccountContextType = {
    account: data?.viewer ?? null,
    fetchAccount: refetch,
  };

  return (
    <AccountContext.Provider value={contextValue}>
      {loading ? (
        <Background>
          <Spinner />
        </Background>
      ) : (
        children
      )}
    </AccountContext.Provider>
  );
}

export function useAccount() {
  const context = React.useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
}

export default AccountProvider;
