const KEY = 'accessToken';

export const getAccessToken = () => {
  let accessTokenObj: string | null = localStorage.getItem(KEY);
  let accessToken: string | null = null;

  if (accessTokenObj) {
    const item = JSON.parse(accessTokenObj);
    if (isAccessTokenExpired()) {
      accessToken = null;
    } else accessToken = item.value;
  }
  return accessToken;
};

export const isAccessTokenExpired = () => {
  let accessTokenObj: string | null = localStorage.getItem(KEY);
  if (accessTokenObj) {
    const item = JSON.parse(accessTokenObj);
    const now = new Date();
    if (now.getTime() > new Date(item.expiry).getTime()) {
      return true;
    }
  }
  return false;
};

export const setAccessToken = (token: string) => {
  const accessToken = {
    value: token,
    expiry: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14),
  };
  localStorage.setItem(KEY, JSON.stringify(accessToken));
};

export const clearAccessToken = () => {
  localStorage.removeItem(KEY);
};
