import React from 'react';
import {
  from,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider as Provider,
  ApolloError,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import * as Sentry from '@sentry/browser';
import { GRAPHQL_ENDPOINT } from '../../config';
import {
  clearAccessToken,
  getAccessToken,
} from '../../helpers/accessTokenStorage';
import history from '../../history';

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (isServerError(networkError) && networkError.statusCode === 401) {
    clearAccessToken();
    history.push('/signin', { isSessionExpired: true });
    return;
  }
  const scope = new Sentry.Scope();
  if (graphQLErrors)
    graphQLErrors.map(({ message }, index) =>
      scope.setExtra(`graphQLError:${index}`, message)
    );
  if (networkError) scope.setExtra('networkError', networkError);
  Sentry.captureException(
    new ApolloError({ graphQLErrors, networkError }),
    scope
  );
});

function isServerError(error: Error | undefined): error is ServerError {
  return error != null && 'statusCode' in error;
}

// @ts-ignore this is a known tpye issue on this rc version which will be fixed in future. does not affect functionality.
const link = from([errorLink, authLink, httpLink]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    possibleTypes: {
      Activity: [
        'DedicatedPracticeActivity',
        'IntegratedPracticeActivity',
        'TestActivity',
        'DiscoveryActivity',
        'ReflectionActivity',
      ],
      DiscoveryPage: ['DiscoveryInfoPage', 'DiscoveryQuotePage'],
      Node: [
        'Account',
        'DedicatedPracticeActivity',
        'IntegratedPracticeActivity',
        'TestActivity',
        'Course',
        'Learner',
        'Lesson',
        'ReflectionActivity',
        'DiscoveryActivity',
      ],
      ReflectionQuestion: [
        'MultiSelectReflectionQuestion',
        'SingleSelectReflectionQuestion',
        'TextReflectionQuestion',
      ],
      ReflectionResponse: [
        'MultiSelectReflectionResponse',
        'SingleSelectReflectionResponse',
        'TextReflectionResponse',
      ],
    },
    typePolicies: {
      TestActivityScorecard: {
        // Test activity scorecards do not have any identifying fields.
        keyFields: [],
      },
      Query: {
        fields: {
          node(cached, { args, toReference, field }) {
            if (typeof cached !== 'undefined') {
              // There's already a cached reference, so return it.
              return cached;
            }
            if (args != null && typeof args.id === 'string') {
              // If the "node" field is aliased to "lesson" then we know that
              // a lesson is being queried and can return a reference to that
              // lesson in the cache.
              if (field?.alias?.value === 'lesson') {
                return toReference({
                  __typename: 'Lesson',
                  id: args.id,
                });
              }
            }
          },
        },
      },
    },
  }),
});

function ApolloProvider({ children }: { children: React.ReactNode }) {
  return <Provider client={client}>{children}</Provider>;
}

export default ApolloProvider;
