import 'intl-pluralrules';
import React, { useContext, useState, useEffect, createContext } from 'react';
import * as Sentry from '@sentry/react';
import { negotiateLanguages } from '@fluent/langneg';
import { FluentBundle, FluentResource } from '@fluent/bundle';
import {
  LocalizationProvider as FluentLocalizationProvider,
  ReactLocalization,
} from '@fluent/react';
import Background from '../Background';
import Spinner from '../Spinner';

type Locale = string;
type MessageSource = string;
type LocaleMessageSources = [Locale, Array<MessageSource>];

const CurrentLocalesContext = createContext<string[] | undefined>(undefined);

const DEFAULT_LOCALE = 'en';
const AVAILABLE_LOCALES = {
  af: 'Afrikaans',
  en: 'English',
};

async function fetchSources(locale: Locale): Promise<LocaleMessageSources> {
  const messagesUri = require(`../../locales/${locale}/messages.ftl`).default;
  const contentUri = require(`../../locales/${locale}/content.ftl`).default;
  const sources = await Promise.all([
    fetchSource(messagesUri),
    fetchSource(contentUri),
  ]);
  return [locale, sources];
}

async function fetchSource(uri: string): Promise<MessageSource> {
  const response = await fetch(uri);
  if (response.status !== 200) {
    throw new Error('Error fetching locale messages');
  }
  return response.text();
}

function* generateBundles(localeSources: Array<LocaleMessageSources>) {
  for (const [locale, sources] of localeSources) {
    const bundle = new FluentBundle(locale);
    for (const source of sources) {
      const resource = new FluentResource(source);
      bundle.addResource(resource);
    }
    yield bundle;
  }
}

interface AppLocalizationProviderProps {
  children: JSX.Element;
}

function LocalizationProvider(props: AppLocalizationProviderProps) {
  const [l10n, setL10n] = useState<ReactLocalization | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [currentLocales, setCurrentLocales] = useState<string[]>([
    DEFAULT_LOCALE,
  ]);

  useEffect(() => {
    async function changeLocales(userLocales: ReadonlyArray<Locale>) {
      try {
        const currentLocales = negotiateLanguages(
          userLocales,
          Object.keys(AVAILABLE_LOCALES),
          { defaultLocale: DEFAULT_LOCALE }
        );
        const fetchedSources = await Promise.all(
          currentLocales.map(fetchSources)
        );
        const bundles = generateBundles(fetchedSources);
        setCurrentLocales(currentLocales);
        setL10n(new ReactLocalization(bundles));
        setError(null);
      } catch (error) {
        Sentry.captureException(error);
        setError('Failed to load Localization Provider');
      }
    }
    changeLocales(navigator.languages);
  }, []);

  // do not block app if Localization bundles fail
  if (error) {
    return props.children;
  }

  if (l10n === null) {
    return (
      <Background>
        <Spinner />
      </Background>
    );
  }

  return (
    <FluentLocalizationProvider l10n={l10n}>
      <CurrentLocalesContext.Provider value={currentLocales}>
        {props.children}
      </CurrentLocalesContext.Provider>
    </FluentLocalizationProvider>
  );
}

export function useCurrentLocales() {
  const currentLocales = useContext(CurrentLocalesContext);
  if (currentLocales === undefined) {
    throw new Error(
      'useCurrentLocales must be used within a LocalizationProvider'
    );
  }
  return currentLocales;
}

export default LocalizationProvider;
