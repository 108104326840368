import * as React from 'react';
import { BrowserHistory, Update } from 'history';
import { Router } from 'react-router-dom';

type Props = {
  children?: React.ReactNode;
  history: BrowserHistory;
};

// Copied from https://github.com/ReactTraining/react-router/pull/7586.
function HistoryRouter({ children, history }: Props) {
  const [state, dispatch] = React.useReducer(
    (_: Update, action: Update) => action,
    { action: history.action, location: history.location }
  );

  React.useLayoutEffect(() => history.listen(dispatch), [history]);

  return (
    <Router
      children={children}
      action={state.action}
      location={state.location}
      navigator={history}
    />
  );
}

export default HistoryRouter;
