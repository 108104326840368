// Sentry must come first so that global handlers can be installed.
import './sentry';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import { FIREBASE_API_KEY, HOTJAR_ID, HOTJAR_VERSION } from './config';
import initialiseHotJar from './hotjar';

import 'firebase/analytics';
import './index.css';
import 'typeface-montserrat';
import 'typeface-roboto';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';

if (FIREBASE_API_KEY == null) {
  console.log('Not initialising Firebase - FIREBASE_API_KEY is not set');
} else {
  firebase.initializeApp({
    apiKey: FIREBASE_API_KEY,
    authDomain: 'level-up-ae561.firebaseapp.com',
    databaseURL: 'https://level-up-ae561.firebaseio.com',
    projectId: 'level-up-ae561',
    storageBucket: 'level-up-ae561.appspot.com',
    messagingSenderId: '946534328754',
    appId: '1:946534328754:web:32d6e447d261a3238d5cab',
    measurementId: 'G-3ZK9L12LCN',
  });
  // Initialise Firebase Analytics.
  firebase.analytics();
}

if (HOTJAR_ID) initialiseHotJar(HOTJAR_ID, HOTJAR_VERSION);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
