import React from 'react';
import * as Sentry from '@sentry/react';
import ApolloProvider from './components/ApolloProvider';
import Router from './components/Router';
import AccountProvider from './components/AccountProvider';
import ThemeProvider from './components/ThemeProvider';
import LocalizationProvider from './components/LocalizationProvider';

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<h1>Something went wrong.</h1>} showDialog>
      <ApolloProvider>
        <AccountProvider>
          <ThemeProvider>
            <LocalizationProvider>
              <Router />
            </LocalizationProvider>
          </ThemeProvider>
        </AccountProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
